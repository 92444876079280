import './TestRunner.css';

import { faCheck, faPaste } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@sparx/sparx-design/components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useCannyTest } from './CannyIntegration';
import { MainHeading } from './MainHeading';
import { ResultBox, ResultStatus } from './ResultBox';
import { TestResultRow } from './TestResultRow';
import { ErrorGroup, ISubTest, ITest, TestResult, tests } from './Tests';

const MESSAGE_TO_IT_TEXT_ID = 'message-to-it-text';

enum TestRunStatus {
  Pending,
  Running,
  Finished,
}

interface TestRunnerProps {
  products: string[];
}

export const TestRunner = ({ products }: TestRunnerProps) => {
  const [results, setResults] = useState<{
    [testName: string]: TestResult[] | undefined;
  }>({});
  const [testRunStatus, setTestRunStatus] = useState(TestRunStatus.Pending);
  const [hasFailed, setHasFailed] = useState<boolean>(false);
  const { checkCannyStatus, CannyTestTarget } = useCannyTest();

  const testToRun = useMemo(
    () => tests.filter(t => !t.ifProduct || products.includes(t.ifProduct)),
    [products],
  );

  const doTestRun = useCallback(async () => {
    setTestRunStatus(TestRunStatus.Running);
    for (const test of testToRun) {
      console.log('running test:', test.name);

      const result: TestResult[] = Array(test.subTests.length).fill(undefined);
      for (const i in test.subTests) {
        const subTest = test.subTests[i];
        const noTestError = new Error(`no test provided for: ${subTest.name}`);
        console.log('running subtest:', subTest.name);

        let testResult: TestResult;
        try {
          if (subTest.useExternalTest) {
            switch (subTest.name) {
              case 'https://canny.io (teachers only)':
                testResult = await checkCannyStatus();
                break;
              default:
                console.error(noTestError);
                testResult = noTestError;
            }
          } else {
            testResult = await subTest.run();
          }
        } catch (e) {
          console.error('subtest threw an exception', e);
          testResult = e as Error;
        }
        if (testResult !== 'passed') {
          setHasFailed(true);
        }
        result[i] = testResult;
        setResults(results => ({
          ...results,
          [test.name]: result,
        }));
      }
      console.log('test result for ', test.name, ': ', result);
    }
    setTestRunStatus(TestRunStatus.Finished);
  }, [testToRun]);

  useEffect(() => {
    if (testRunStatus === TestRunStatus.Pending) {
      doTestRun();
    }
  }, [doTestRun, testRunStatus]);

  const resetTestRun = () => {
    setResults({});
    setTestRunStatus(TestRunStatus.Pending);
    setHasFailed(false);
  };

  const { copied, onCopy } = useCopyableValue(() => {
    const messageTextNode = document.getElementById(MESSAGE_TO_IT_TEXT_ID);
    if (!messageTextNode) {
      return '';
    }
    return messageTextNode.innerText;
  });

  let result: React.ReactNode = <ResultBox resultStatus={ResultStatus.Checking} />;
  if (testRunStatus === TestRunStatus.Finished) {
    result = (
      <div className="result-summary">
        <h2>Your Result</h2>
        <ResultBox resultStatus={ResultStatus.Success} />
        <p>
          Remember, you’ll need to visit this website on at least one student and one teacher device
          that will be used to access Sparx to check that they are both compatible.
        </p>
        <p className="reset-tests-button-container">
          Click{' '}
          <Button variant="text" onClick={resetTestRun}>
            here
          </Button>{' '}
          to run the tests again.
        </p>
      </div>
    );
    if (hasFailed) {
      result = (
        <div className="result-summary">
          <h2>Your Result</h2>
          <ResultBox resultStatus={ResultStatus.Failed} />
          <p>The information below explains what's wrong and how to fix it.</p>
          <p>
            If you’re not sure how to fix these issues we suggest copying the text below and sending
            it to your IT team:
          </p>
          <div className="message-to-it">
            <div id={MESSAGE_TO_IT_TEXT_ID}>
              Hello,
              <br />
              <br />
              We’re going to start using one or more Sparx Learning products which require both
              staff and student access. I&apos;ve used the Sparx Learning compatibility checker (
              <a rel="noreferrer" href="https://check.sparx-learning.com" target="_blank">
                https://check.sparx-learning.com
              </a>
              ) to see if Sparx will work with my device’s current settings, and been told that it
              doesn&apos;t meet the requirements below:
              <br />
              <TestResults tests={testToRun} results={results} />
              <br />
              Please could you look into checking and solving these issues, on both teacher and
              student devices (where applicable), so that we can get started with Sparx as soon as
              possible.
              <br />
              <br />
              If you have any questions, please contact{' '}
              <a
                rel="noreferrer"
                href="mailto:schoolsupport@sparx.co.uk?subject=Sparx Compatibility Checker"
                target="_blank"
              >
                schoolsupport@sparx.co.uk
              </a>
              .
              <br />
              <br />
              Kind regards
            </div>
            <div className="button-container">
              <Button
                onClick={onCopy}
                leftIcon={<FontAwesomeIcon fixedWidth={true} icon={copied ? faCheck : faPaste} />}
              >
                Copy text to clipboard
              </Button>
            </div>
          </div>
          <p className="reset-tests-button-container">
            Click{' '}
            <Button variant="text" onClick={resetTestRun}>
              here
            </Button>{' '}
            to run the tests again.
          </p>
        </div>
      );
    }
  }

  return (
    <>
      <div className="instructions">
        <MainHeading />
        {result}
        {CannyTestTarget}
      </div>
      <div>
        {testToRun.map((test, i) => {
          const result = results[test.name];
          return <TestResultRow key={i} test={test} result={result} />;
        })}
      </div>
    </>
  );
};

const useCopyableValue = (getValue: () => string) => {
  const [copied, setCopied] = useState(false);

  const onCopy = () => {
    navigator.clipboard.writeText(getValue());
    setCopied(true);
  };
  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }, [copied]);

  return { copied, onCopy };
};

const TestResults = ({
  tests,
  results,
}: {
  tests: ITest[];
  results: { [p: string]: TestResult[] | undefined };
}) => {
  const groupedTests: Record<ErrorGroup, ISubTest[]> = useMemo(() => {
    const grouped: Record<ErrorGroup, ISubTest[]> = { url: [], browser: [] };
    for (const test of tests) {
      const result = results[test.name];
      for (const i in result || []) {
        const r = result && result[i];
        const subtest = test.subTests[i];
        if (r && r instanceof Error) {
          grouped[subtest.errorGroup].push(subtest);
        }
      }
    }
    return grouped;
  }, [tests, results]);

  const groupAndMessage: [ErrorGroup, React.ReactNode][] = [
    [
      'url',
      <>
        These web addresses appear to be blocked or inaccessible. Sparx recommends that these are
        whitelisted in the firewall for all devices and users:
      </>,
    ],
    [
      'browser',
      <>
        The browser does not support modern browser features required for Sparx. Sparx recommends
        that you update your web browser to the latest version.
      </>,
    ],
  ];

  return (
    <>
      {groupAndMessage.map(
        ([group, message]) =>
          groupedTests[group].length > 0 && (
            <>
              <br />
              {message}
              <br />
              <br />
              {groupedTests[group].map((g, i) => (
                <div className="message-to-it-item" key={i}>
                  - {g.errorMsg}
                </div>
              ))}
            </>
          ),
      )}
    </>
  );
};
