import { URLTest } from './Tests';

/**
 * Call fetch but races with a timeout.
 * @param url - url to fetch
 * @param options - fetch options
 * @param timeout - timeout on fetch race
 */
const fetchWithTimeout = async (
  url: string,
  options: RequestInit = {},
  timeout = 2000,
): Promise<undefined | Response> => {
  return Promise.race([
    fetch(url, options),
    new Promise<undefined>((_, reject) => setTimeout(() => reject(new Error('timeout')), timeout)),
  ]);
};

/**
 * Attempts to fetch a URL and returns a promise which resolves to if
 * it was successful or not.
 * @param urlTest - configuration for the test
 */
export const tryFetchURL = async (urlTest: URLTest): Promise<boolean> => {
  try {
    const response: Response | undefined = await fetchWithTimeout(urlTest.url);
    if (response) {
      let urlOK: boolean;
      if (urlTest.expectedCodes) {
        urlOK = !!urlTest.expectedCodes.find(c => response && c === response.status);
      } else {
        urlOK = response.status === 200;
      }
      const bodyText = await response.text();
      const bodyOK = !urlTest.expectedText || urlTest.expectedText === bodyText;
      const bodyContainsOk =
        !urlTest.expectedTextContains || bodyText.includes(urlTest.expectedTextContains);
      return urlOK && bodyOK && bodyContainsOk;
    }
  } catch (e) {
    // Console log the error for debugging, it fails following this.
    console.log("Failed: ", urlTest.url, JSON.stringify(e));
  }
  return false;
};
