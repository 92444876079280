import './index.css';

import App from 'components/App';
import React from 'react';
import ReactDOM from 'react-dom/client';

// Remove the prerendered content as soon as script starts running
document.getElementById('prerender')?.remove();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
