import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCaretDown,
  faCaretRight,
  faCheck,
  faExclamationTriangle,
  faInfoCircle,
  faSpinner,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState } from 'react';

import { ITest, TestResult } from './Tests';

export const TestResultRow: React.FC<{
  test: ITest;
  result?: TestResult[];
}> = ({ result, test }) => {
  const [open, setOpen] = useState<boolean>(false);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const contentHeight = contentRef.current && open ? contentRef.current.clientHeight : 0;

  return (
    <div className="test-table">
      <div className="test-table-header" onClick={() => setOpen(!open)}>
        <div className="test-table-chevron">
          <FontAwesomeIcon icon={open ? faCaretDown : faCaretRight} fixedWidth={true} />
        </div>
        <div className="test-table-name">{test.name}</div>
        <div className="test-table-info">
          {result ? result.filter(r => r === 'passed').length : 0} / {test.subTests.length}
        </div>
        <div className="test-table-result">{getIcon(result ? result : undefined)}</div>
      </div>
      <div className="test-table-hidden" style={{ height: contentHeight }}>
        <div ref={contentRef} className="test-table-content">
          {(test.description || []).map((v, i) => (
            <p key={i}>{v}</p>
          ))}
          <table className="test-table-results">
            {test.subTests.map((subtest, j) => {
              return (
                <tr key={j} className="test-table-subtest">
                  <td>
                    {subtest.name}
                    {subtest.info ? (
                      <div className="icon-wrapper">
                        &nbsp;
                        <FontAwesomeIcon icon={faInfoCircle} />
                        <p className="show-on-hover">{subtest.info}</p>
                      </div>
                    ) : null}
                  </td>
                  <td>{getIcon(result ? result[j] : undefined)}</td>
                </tr>
              );
            })}
          </table>
        </div>
      </div>
    </div>
  );
};

function getIcon(result: TestResult | TestResult[] | undefined) {
  let resultIcon: IconProp;
  if (!result) {
    resultIcon = faSpinner;
  } else if (Array.isArray(result)) {
    const results = result.map(r => getIconNameFromResult(r));
    if (results.filter(r => r === faTimes).length > 0) {
      resultIcon = faTimes;
    } else if (results.filter(r => r === faExclamationTriangle).length > 0) {
      resultIcon = faExclamationTriangle;
    } else if (results.filter(r => r === faSpinner).length > 0) {
      resultIcon = faSpinner;
    } else {
      resultIcon = faCheck;
    }
  } else {
    resultIcon = getIconNameFromResult(result);
  }
  return <FontAwesomeIcon icon={resultIcon} spin={resultIcon === faSpinner} fixedWidth={true} />;
}

function getIconNameFromResult(result: TestResult | undefined): IconProp {
  if (result && result === 'passed') {
    return faCheck;
  } else if (result && result instanceof Error) {
    return faTimes;
  } else if (result && result.warning) {
    return faExclamationTriangle;
  }
  return faSpinner;
}
