import SparxLogo from '@sparx/sparx-design/logos/sparx_learning_logo.png';
import React, { useState } from 'react';

import { ChooseProducts } from './ChooseProducts';
import { TestRunner } from './TestRunner';

const App: React.FC = () => {
  const [products, setProducts] = useState<string[]>([]);

  return (
    <div className="App">
      <header>
        <h1 onClick={() => setProducts([])}>
          <img src={SparxLogo} alt="Sparx" className="sparx-logo" />
          Compatibility Checker
        </h1>
      </header>

      <div className="Content">
        {products.length === 0 ? (
          <ChooseProducts onChoose={setProducts} />
        ) : (
          <TestRunner products={products} />
        )}
      </div>

      <div className="sparx-link">
        <a href="https://sparx-learning.com" target="_blank" rel="noopener noreferrer">
          sparx-learning.com
        </a>
      </div>

      <footer>
        Sparx sets &lsquo;cookies&rsquo; on your device to deliver this service. Find out more in
        our{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://sparxmaths.com/sparx-maths-terms-and-conditions/"
        >
          terms of use
        </a>{' '}
        and{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://sparxmaths.com/privacy-policy-for-schools/"
        >
          privacy policy
        </a>
        . <span className="copyright">&copy; Sparx Limited 2016-{new Date().getFullYear()}</span>
      </footer>
    </div>
  );
};

export default App;
