import classNames from 'classnames';

import { ComponentWithAs } from '../../ComponentWithAs';
import styles from './HiddenAt.module.css';

/** Breakpoint maps to the available breakpoints defined in base.css */
export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

interface HiddenAtProps {
  /** Breakpoint at which to hide the content */
  breakpoint: Breakpoint;
  className?: string;
}

const classes: Record<Breakpoint, string> = {
  xs: styles.Xs,
  sm: styles.Sm,
  md: styles.Md,
  lg: styles.Lg,
  xl: styles.Xl,
  xxl: styles.Xxl,
};

/**
 * Allows you to hide children below certain breakpoints to avoid having to
 * write the same media query for those elements.
 *
 * Breakpoints map to the CSS variables in base.css.
 */
export const HiddenAt: ComponentWithAs<'div', HiddenAtProps> = ({
  as: Component = 'div',
  breakpoint,
  className,
  ...rest
}) => {
  return (
    <Component className={classNames(styles.HiddenAt, classes[breakpoint], className)} {...rest} />
  );
};

/**
 * Allows you to hide children above certain breakpoints to avoid having to
 * write the same media query for those elements.
 *
 * Breakpoints map to the CSS variables in base.css.
 */
export const ShownUntil: ComponentWithAs<'div', HiddenAtProps> = ({
  as: Component = 'div',
  breakpoint,
  className,
  ...rest
}) => {
  return (
    <Component
      className={classNames(styles.HiddenAt, styles.ShownUntil, classes[breakpoint], className)}
      {...rest}
    />
  );
};
