import { faCheck, faExclamationTriangle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export enum ResultStatus {
  Success,
  Failed,
  Checking,
}

export const ResultBox: React.FC<{
  resultStatus: ResultStatus;
}> = ({ resultStatus }) => {
  switch (resultStatus) {
    case ResultStatus.Success:
      return (
        <div className="result result-ok">
          <FontAwesomeIcon icon={faCheck} fixedWidth={true} />
          Great! This device is compatible with Sparx.
        </div>
      );
    case ResultStatus.Failed:
      return (
        <div className="result result-failed">
          <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth={true} />A few tests have
          failed...
        </div>
      );
    case ResultStatus.Checking:
      return (
        <div className="result result-checking">
          <FontAwesomeIcon icon={faSpinner} spin={true} fixedWidth={true} />
          Checking this device...
        </div>
      );
    default:
      throw new Error('Unexpected result status');
  }
};
