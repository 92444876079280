// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

export const renderCanny = (onLoadCallback: () => void) => {
  // eslint-disable-next-line no-undef
  Canny('render', {
    boardToken: 'aa60b839-2fbe-68f2-4c8f-7bc88c5107ea', // Public Sparx Maths Board token https://sparxlearning.canny.io/admin/settings/boards/sparx-maths-feedback/general
    onLoadCallback: onLoadCallback,
  });
}

export const loadCannySDK = () => {
  const CANNY_SDK_ID = 'canny-jssdk';
  const SCRIPT_TAG_NAME = 'script';
  const CANNY_SRC = 'https://canny.io/sdk.js';
  function l() {
    if (!document.getElementById(CANNY_SDK_ID)) {
      const f = document.getElementsByTagName(SCRIPT_TAG_NAME)[0],
        e = document.createElement(SCRIPT_TAG_NAME);
      e.type = 'text/javascript';
      e.async = true;
      e.src = CANNY_SRC;
      f.parentNode.insertBefore(e, f);
    }
  }

  if (typeof window.Canny !== 'function') {
    const c = function() {
      // eslint-disable-next-line prefer-rest-params
      c.q.push(arguments);
    };
    c.q = [];
    window.Canny = c;
    document.readyState === 'complete'
      ? l()
      : window.attachEvent
        ? window.attachEvent('onload', l)
        : window.addEventListener('load', l, false);
  }
};
