export const MainHeading = () => (
  <>
    <h2>Welcome to the Sparx Compatibility Checker</h2>
    <p>
      The Sparx Compatibility Checker will run a few tests to check that this device will be able to
      successfully use Sparx. Please make sure you are visiting this website on the device that will
      be used to access Sparx, including at least one student and one teacher device.
    </p>
  </>
);
