import React from 'react';

import { TestResult } from '../Tests';
import { loadCannySDK, renderCanny } from './CannyWidget';

export const useCannyTest = (): {
  checkCannyStatus: () => Promise<TestResult>;
  CannyTestTarget: React.ReactElement;
} => {
  React.useEffect(() => {
    loadCannySDK();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    checkCannyStatus: cannyPromise,
    CannyTestTarget: <div data-canny="" style={{ display: 'none' }} />,
  };
};

const cannyPromise = () =>
  new Promise<TestResult>((resolve, reject) => {
    loadCannySDK();
    const timeout = setTimeout(() => {
      reject(new Error('canny timeout'));
      cleanupTest(timeout);
    }, 10000);
    renderCanny(() => {
      resolve('passed');
      cleanupTest(timeout);
    });
  });

const cleanupTest = (timeout: number) => {
  clearTimeout(timeout);
  document.getElementById('canny-iframe')?.remove();
}
