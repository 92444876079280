import { faCheck, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@sparx/sparx-design/components';
import { ReactComponent as AssessmentsLogo } from '@sparx/sparx-design/logos/sparx_assessments_logo.svg';
import { ReactComponent as MathsLogo } from '@sparx/sparx-design/logos/sparx_maths_logo.svg';
import { ReactComponent as ReaderLogo } from '@sparx/sparx-design/logos/sparx_reader_logo.svg';
import { ReactComponent as ScienceLogo } from '@sparx/sparx-design/logos/sparx_science_logo.svg';
import clsx from 'clsx';
import React, { useMemo, useState } from 'react';

import { MainHeading } from './MainHeading';

interface Product {
  key: string;
  name: string;
  logo: React.FunctionComponent;
  hidden?: boolean;
}

const products: Product[] = [
  {
    key: 'maths',
    name: 'Sparx Maths',
    logo: MathsLogo,
  },
  {
    key: 'reader',
    name: 'Sparx Reader',
    logo: ReaderLogo,
  },
  {
    key: 'science',
    name: 'Sparx Science',
    logo: ScienceLogo,
  },
  {
    key: 'assessments',
    name: 'Sparx Assessments',
    logo: AssessmentsLogo,
    hidden: true,
  },
];

interface ChooseProductsProps {
  onChoose: (products: string[]) => void;
}

export const ChooseProducts = ({ onChoose }: ChooseProductsProps) => {
  const defaultProducts = useMemo(() => {
    const products = new URLSearchParams(window.location.search).getAll('p') || [];
    if (!products.includes('maths')) products.push('maths');
    return products;
  }, []);
  const [selected, setSelected] = useState(defaultProducts);

  const toggleProduct = (key: string) => {
    if (selected.includes(key)) {
      setSelected(selected.filter(k => k !== key));
    } else {
      setSelected([...selected, key]);
    }
  };

  return (
    <div className="instructions">
      <MainHeading />
      <p>Select the products that you would like to test for:</p>
      <div className="product-list">
        {products
          .filter(p => !p.hidden || defaultProducts.includes(p.key))
          .map(p => (
            <ProductButton
              key={p.key}
              onToggle={() => toggleProduct(p.key)}
              p={p}
              selected={selected.includes(p.key)}
            />
          ))}
        <div className="button-container">
          <Button
            variant="contained"
            isDisabled={selected.length === 0}
            onClick={() => onChoose(selected)}
            rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
          >
            Run tests
          </Button>
        </div>
      </div>
    </div>
  );
};

interface ProductButtonProps {
  p: Product;
  selected?: boolean;
  onToggle: () => void;
}

const ProductButton = ({ p, selected, onToggle }: ProductButtonProps) => {
  const Logo = p.logo;
  return (
    <div onClick={onToggle} className={clsx('product', selected && 'product-selected')}>
      <div className={clsx('checkbox', selected && 'checkbox-selected')}>
        <FontAwesomeIcon icon={faCheck} />
      </div>
      <Logo />
    </div>
  );
};
